import React, { useRef, useState, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';

const PDF417Scanner = ({ onScan }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [captureResult, setCaptureResult] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const reader = useRef(new BrowserMultiFormatReader());

  useEffect(() => {
    return () => {
      stopScanning();
    };
  }, []);

  const startScanning = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (!videoDevices.length) {
        setError('No se encontraron cámaras disponibles.');
        return;
      }

      const selectedDevice =
        videoDevices.find((device) =>
          device.label.toLowerCase().includes('back') ||
          device.label.toLowerCase().includes('trasera')
        ) || videoDevices[0];

      const constraints = {
        video: {
          deviceId: selectedDevice.deviceId,
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      };

      await reader.current.decodeFromVideoDevice(
        selectedDevice.deviceId,
        videoRef.current,
        (scanResult) => {
          if (scanResult) {
            const text = scanResult.getText();
            setResult(text);
            if (onScan) onScan(text);
          }
        },
        constraints
      );

      setIsScanning(true);
      setError(null);
    } catch (err) {
      console.error(err);
      setError('Error al iniciar la cámara. Por favor, verifica los permisos.');
      setIsScanning(false);
    }
  };

  const stopScanning = () => {
    reader.current.reset();
    setIsScanning(false);
  };

  const captureAndShowImage = async () => {
    try {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      const cropX = 0; // Coordenada X inicial
      const cropY = 100; // Coordenada Y inicial
      const cropWidth = 800; // Ancho del área recortada
      const cropHeight = 200; // Alto del área recortada

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      context.drawImage(video, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      const imageData = canvas.toDataURL('image/png');
      setCapturedImage(imageData);

      const analyzedResult = await analyzeImage(imageData);
      setCaptureResult(analyzedResult);
    } catch (err) {
      console.error(err);
      console.error('Error al capturar la imagen:', err);
    }
  };

  const analyzeImage = async (imageData) => {
    try {
      const img = new Image();
      img.src = imageData;
      await new Promise((resolve) => (img.onload = resolve));

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      const result = await reader.current.decodeFromCanvas(canvas);
      return result ? result.getText() : 'No se pudo detectar el código.';
    } catch (err) {
      console.error('Error al analizar la imagen:', err);
      return 'Error al analizar la imagen.';
    }
  };

  return (
    <div style={{ margin: '0 auto', padding: '20px' }}>
      <div style={{ position: 'relative', width: 800, height: 200, margin: '0 auto' }}>
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            border: '2px solid #ccc',
            borderRadius: '8px',
          }}
        />
        {isScanning && (
          <div
            style={{
              position: 'absolute',
              bottom: '0%',
              left: '50%',
              transform: 'translate(-50%, -2%)',
              width: '98%',
              height: '96%',
              border: '2px solid #00ff00',
              boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.3)',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button
          onClick={isScanning ? stopScanning : startScanning}
          style={{
            padding: '10px 20px',
            backgroundColor: isScanning ? '#ff4444' : '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          {isScanning ? 'Detener Scanner' : 'Iniciar Scanner'}
        </button>
        {isScanning && (
          <button
            onClick={captureAndShowImage}
            style={{
              marginLeft: '10px',
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Tomar Foto
          </button>
        )}
      </div>

      {error && (
        <div style={{ color: '#721c24', backgroundColor: '#f8d7da', padding: '10px', marginTop: '10px', borderRadius: '4px', textAlign: 'center' }}>
          {error}
        </div>
      )}

      {result && (
        <div style={{ marginTop: '20px', padding: '15px', backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <h4>Último código escaneado:</h4>
          <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{result}</pre>
        </div>
      )}

      {capturedImage && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <h4>Imagen Capturada:</h4>
          <img
            src={capturedImage}
            alt="Imagen capturada"
            style={{
              width: '100%',
              maxHeight: '300px',
              objectFit: 'contain',
              border: '2px solid #ccc',
              borderRadius: '8px',
            }}
          />
        </div>
      )}

      {captureResult && (
        <div style={{ marginTop: '20px', padding: '15px', backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
          <h4>Resultado de la imagen capturada:</h4>
          <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{captureResult}</pre>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default PDF417Scanner;
